<template>
  <div>联系我们</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>