import Vue from 'vue'
import VueRouter from 'vue-router';
import index from "../views/index.vue";
import shouye from "../components/shouye";
import jiudianjieshao from "../components/jiudianjieshao";
import kefangzhongxin from "../components/kefangzhongxin";
import huiyiyanhui from "../components/huiyiyanhui";
import langmanhunyan from "../components/langmanhunyan";
import tesecanyin from "../components/tesecanyin";
import kangtiyule from "../components/kangtiyule";
import lianxiwomen from "../components/lianxiwomen";



Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    alias: '/index',
    name: 'home',
    component: index,
    children:[{
      path: '/index/shouye',
      name: 'shouye',
      component: shouye
      },{
        path: '/index/jiudianjieshao',
        name: 'jiudianjieshao',
        component: jiudianjieshao
      },{
      path: '/index/kefangzhongxin',
      name: 'kefangzhongxin',
      component: kefangzhongxin
    },{
      path: '/index/huiyiyanhui',
      name: 'huiyiyanhui',
      component: huiyiyanhui
    },{
      path: '/index/langmanhunyan',
      name: 'langmanhunyan',
      component: langmanhunyan
    },{
      path: '/index/tesecanyin',
      name: 'tesecanyin',
      component: tesecanyin
    },{
      path: '/index/kangtiyule',
      name: 'kangtiyule',
      component: kangtiyule
    },{
      path: '/index/lianxiwomen',
      name: 'lianxiwomen',
      component: lianxiwomen
    }
    ]
  }
]

const router = new VueRouter({
  routes:routes,
  mode:"history", //hash,history 带#
})
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function(location,resolve,reject){
  if(resolve && reject){
    // call和apply区别
    // 相同点：都可以调用函数一次，都可以篡改函数的上下文一次
    // 不同点：call和apply传递参数：call传递参数用逗号隔开，apply方法传递数组
    originPush.call(this,location,resolve,reject)
  }else{
    originPush.call(this,location,() => {}, () => {})
  }
}
VueRouter.prototype.replace = function(location,resolve,reject){
  if(resolve && reject){
    originReplace.call(this,location,resolve,reject)
  }else{
    originReplace.call(this,location,() => {}, () => {})
  }
}
router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
    document.title = to.meta.title
  }else{
    document.title ="中国石化会议中心 - 中石化北京会议中心"
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})
export default router
