<template>
  <div class="banner">
    <div class="logoContainer">
      <img src="../assets/logo1.jpg" class="logo"/>
      <img src="../assets/titlePicture.jpg" class="titlePicture"/>
    </div>
    <div class="navContainer">
      <div v-for="(navItem,index) in navItems" :key="index"
           class="nav" :class="index == active?'active':''"
           @click="toPage(index)" >
        <router-link :to="navItem.path">{{ navItem.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data:function (){
    return {
      active: 0,//激活了的导航
      navItems6:[
        { title: '首页', path: '/index/shouye' },
        { title: '酒店介绍', path: '/index/jiudianjieshao' },//index/jiudianjieshao 是实际业务的需求;/index/shouye 是当前业务的需求
        { title: '客房中心', path: '/index/kefangzhongxin' },
        { title: '会议宴会', path: '/index/huiyiyanhui' },
        { title: '浪漫婚宴', path: '/index/langmanhunyan' },
        { title: '特色餐饮', path: '/index/tesecanyin' },
        { title: '康体娱乐', path: '/index/kangtiyule' },
        { title: '联系我们', path: '/index/lianxiwomen' },
      ],
      navItems:[
        { title: '首页', path: '/index/shouye'},
        { title: '酒店介绍', path: '/index/jiudianjieshao'},//index/jiudianjieshao 是实际业务的需求;/index/shouye 是当前业务的需求
        { title: '客房中心', path: '/index/shouye' ,query: 3},
        { title: '会议宴会', path: '/index/shouye' ,query: 4},
        { title: '浪漫婚宴', path: '/index/shouye' ,query: 5},
        { title: '特色餐饮', path: '/index/shouye' ,query: 6},
        { title: '康体娱乐', path: '/index/shouye' ,query: 7},
        { title: '联系我们', path: '/index/shouye' ,query: 8},
      ],
    }
  },
  methods: {
    toPage (e) {
      this.active=e
      this.$router.push({path: this.navItems[e].path,query:{"id":e}})
    }
  },
  created() {
    this.toPage(0)
  }
}
</script>

<style scoped lang="less">
.banner {
  overflow: hidden;
  height: 200px;
  //overflow-x: hidden;
  width: 100%;
  //background-color: pink;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  //padding-top: 10px;

  .logoContainer{
    width: 100%;
    height: 140px;
    //background-color: gold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 90px;
    .logo{
      height: 94.5%;
    }
    .titlePicture{
      height: 92%;
    }
  }
  .navContainer{
    width: 100%;
    height: 50px;
    background-color: #2B2B2B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      width: 100px;
      height: 100%;
      margin: 0 18.5px;
      line-height: 50px;
      a{
        text-decoration: none;
        color: white;
        font-family: 微软雅黑;
        font-size: 16px;
      }
    }
    div:hover{
      background-color: #FF6637;
    }
    .active{
      background-color: #FF6637;
    }
  }
}
</style>