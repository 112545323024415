<template>
  <div class="servicesBox">
    <div class="title1">
        <span style="font-size: 36px;">我们的<span style="font-size: 75px;">
          <i>
            <b>5</b>
          </i>
        </span>
        </span>
      <b>
        <i>
          <span style="font-size: 36px;">大服务</span>
        </i>
      </b>
    </div>
    <div class="title2">
      —————— &nbsp; FIVE OF OUR SERVICES &nbsp; ——————<br>
    </div>
    <div class="servicesContainer">
      <div class="serviceItem">
        <div class="checkerboard"><i class="iconfont icon-a-xingzhuang7 icon"></i></div>
        <div class="checkerboard2">
          <div class="title">
            商务会议
          </div>
          <div class="content">
            &nbsp;&nbsp;&nbsp;&nbsp;在如此幽美的环境中与同道欢聚一堂，
            聊家事、商国事、议天下事，又怎能不“以心相会”？
            我们拥有大中小型会议厅22个，
            最大的报告厅可容纳550位宾客。无论是气势恢宏的报告大厅，
            还是精致典雅的小会议室，都可以感受到我们的精心、匠心与诚心。
          </div>
        </div>
        <div class="checkerboard3" style="border-left: 0;border-bottom: 0;border-right: 0;">
          <div class="number">
            <i>01</i>
          </div>
        </div>
      </div>
      <div class="serviceItem">
        <div class="checkerboard3" style="border: 0;">
          <div class="number">
            <i>02</i>
          </div>
        </div>
        <div class="checkerboard" style="border-right: 0;border-left: 0;"><i class="iconfont icon-cunqujiu icon" style="font-size: 50px;"></i></div>
        <div class="checkerboard4" style="border:0;">
          <div class="title">
            浪漫婚宴
          </div>
          <div class="content" >
            &nbsp; &nbsp; &nbsp; &nbsp;静谧青山，苍松翠柏，野花漫道，
            落英缤纷，不时传过一两声不知名的鸟叫，野性恣肆，
            生机盎然。望眼前汩汩泉水，鱼儿在其中欢快徜徉、摇曳。
            在这样一个优雅环境，临池垂钓，或来场浪漫婚礼，无疑是人生一大乐事......
          </div>
        </div>
      </div>
      <div class="serviceItem">
        <div class="checkerboard"><i class="iconfont icon-zaocan icon" style="font-size: 60px"></i></div>
        <div class="checkerboard2">
          <div class="title">
            特色餐饮
          </div>
          <div class="content">
            &nbsp; &nbsp; &nbsp; &nbsp;明窗净几、宁静典雅，特色美食及环境会使您愉悦非常。
            无论以精致、淡雅、用料考究的官府菜，还是“麻、辣、鲜、香”的川菜、香酸辣的湘菜，亦或是朴实无华的农家菜，都将淋漓尽致地满足您味觉的渴望，为您开启在会议中心得全新美食旅程......
          </div>
        </div>
        <div class="checkerboard3" style="border: 0">
          <div class="number">
            <i>03</i>
          </div>
        </div>
      </div>
      <div class="serviceItem">
        <div class="checkerboard3" style="border: 0">
          <div class="number">
            <i>04</i>
          </div>
        </div>
        <div class="checkerboard" style="border-left: 0;border-right: 0"><i class="iconfont icon-jianshen- icon" style="font-size: 50px"></i></div>
        <div class="checkerboard4">
          <div class="title">
            健身休闲
          </div>
          <div class="content" >
            &nbsp; &nbsp; &nbsp; &nbsp;静谧青山，长松翠柏，野花漫道，落英缤纷，
            不时传过一两声不知名的鸟叫，野性恣肆，生机盎然。
            客房楼一大片醉人的绿色腾萝沿墙飞流直下，煞有气势。绿瀑旁
            南侧的康体楼内设有游泳馆、乒乓球馆、
            台球室、KTV、棋牌室、健身房，美容美发，随君选择。
          </div>
        </div>
      </div>
      <div class="serviceItem">
        <div class="checkerboard"><i class="iconfont icon-youyongchi icon" style="font-size: 60px"></i></div>
        <div class="checkerboard2">
          <div class="title">
            温泉游泳
          </div>
          <div class="content">
            &nbsp; &nbsp; &nbsp; &nbsp;来自地下3800米侏罗纪白云岩的温泉就在这里。
            可谓真正的天然温泉,无丝毫人工掺杂。历代名家对温泉的描述以及评说不胜枚举，
            天然的清澈，让一整天忙碌的 都随之变得轻盈许多......
          </div>
        </div>
        <div class="checkerboard3" style="border: 0">
          <div class="number">
            <i>05</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "servicesBox"
}
</script>

<style scoped lang="less">
.servicesBox{
  width: 100%;
  height: 950px;
  background-color: skyblue;
  background:url("../../assets/shouye/service/servicebackground.jpg") no-repeat top;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .title1{
    padding-top: 50px;
  }
  .title2{
    padding-bottom: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
  .servicesContainer{
    width: 900px;
    height: 670px;
    //background-color: sandybrown;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .serviceItem{
      width: 170px;
      height: 100%;
      //background-color: saddlebrown;
      .checkerboard{
        box-sizing: border-box;
        border: white 1px solid;
        width: 100%;
        height: 166px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .icon{
          font-size: 40px;
        }
      }
      .checkerboard2{
        box-sizing: border-box;
        border: white 1px solid;
        width: 100%;
        height: 332px;
        background-color: white;
        text-align: center;
        color: #ff6537;
        padding: 0 2px 02px;
        .title{
          padding-top: 5px;
          font-size: 20px;
          height: 50px;
          line-height: 40px;
        }
        .content{
          font-size: 13px;
          font-family: 微软雅黑;
          line-height: 25px;
          padding: 0 10px 0 5px;
          text-align: left;
        }
      }
      .checkerboard3{
        box-sizing: border-box;
        border: white 1px solid;
        width: 100%;
        height: 166px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .number{
          text-align: center;
          font-size: 70px;
          font-font: 微软雅黑, Microsoft YaHei;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
      .checkerboard4{
        box-sizing: border-box;
        border: 0;
        width: 100%;
        height: 332px;
        background-color: #FAA142;
        text-align: center;
        color: white;
        padding: 0 2px 02px;
        .title{
          padding-top: 5px;
          font-size: 20px;
          height: 50px;
          line-height: 40px;
        }
        .content{
          font-size: 13px;
          font-family: 微软雅黑;
          line-height: 25px;
          padding: 0 10px 0 5px;
          text-align: left;
        }
      }
    }
  }
}

</style>