<template>
  <div class="allbox">
    <div class="imgbox">
      <img class="img" src="../../assets/shouye/lunbotu4.jpeg" alt="">
    </div>
    <div class="textbox">
      <div class="title1">会议中心简介</div>
      <div class="title2">
        <span class="e5e5e5">——————</span>&nbsp; HOTEL INTRODUCTION &nbsp; <span class="e5e5e5">——————</span>
      </div>
      <div class="content">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国石化会议中心，位于素有 “北京后花园”之称的昌平区境内,距市区30公里，（京藏高速路31出口，驾车10分钟即到）。会议中心背靠十三陵水库，依山傍水，院内园林景观设计独具匠心、别具一格，山水相间、交相掩映，花草树木、错落有致，空气新鲜、清静典雅，素有天然氧吧的美誉。<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会议中心占地12万平方米，集客房、会议、餐饮、康体于一体，拥有温馨客房468套，大型宴会厅、自助餐厅各1个以及9个各具特色的VIP包间，能同时容纳800人就餐。有可容纳550人的报告厅以及21个中小型会议室，以及网球馆、羽毛球馆、保龄球馆、台球室、乒乓球室、健身房、歌舞厅、KTV包间、游泳馆、温泉池等多种休闲娱乐设施，各项接待设施先进完善、品质一流，是会议培训、观光游览、休闲度假的绝佳去处，其综合接待水平居昌平之首，北京地区前列。
      </div>
    </div>
    <div class="imgcontainer">
      <div class="imgbox2">
        <div class="imgline">
          <img class="img1" src="../../assets/jiudianjieshao/1.jpg" alt="">
          <img class="img2" src="../../assets/jiudianjieshao/2.jpg" alt="">
          <img class="img2" src="../../assets/jiudianjieshao/3.jpg" alt="">
        </div>
        <div class="imgline">
          <img class="img1" src="../../assets/jiudianjieshao/4.jpg" alt="">
          <img class="img2" src="../../assets/jiudianjieshao/5.jpg" alt="">
          <img class="img3" src="../../assets/jiudianjieshao/6.jpg" alt="">
          <img class="img3" src="../../assets/jiudianjieshao/7.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped  lang="less">
.allbox{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  //background-color: salmon;
  .imgbox{
    width: 100%;
    height: 500px;
    .img{
      width: 100%;
      height: 100%;
    }
  }
  .textbox{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    //background-color: seashell;
    padding-top: 40px;
    .title1{
      font-size: 30px;
      line-height: 68px;
    }
    .title2{
      text-align: center;
      font-size: 20px;
      color: #555555;
      .e5e5e5{
        color: #e5e5e5;
      }
    }
    .content{
      font-size: 14px;
      color:rgb(169, 169, 169);
      padding-top: 40px;
      width: 1200px;
      line-height: 38px;
      text-align: left;
    }
  }
  .imgcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-color: lawngreen;
    margin-top: 20px;
    .imgbox2{
      width: 900px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      .imgline{
        width: 100%;
        height: 200px;
        background-color: seashell;
        border-top: 4px solid white;
        .img1{
          width: 25%;
          height: 100%;
          //background-color: seagreen;
        }
        .img2{
          width: 37.5%;
          height: 100%;
          //background-color: sandybrown;
        }
        .img3{
          width: 18.75%;
          height: 100%;
          //background-color: sandybrown;
        }
      }
    }
  }
}
</style>