<template>
  <div class="advantages">
    <div class="title1">
          <span class="t1">
              <b>我们的
                <span class="t2">
                    <i>4 </i>
                </span>
                大优势
              </b>
          </span>
    </div>
    <div class="title2">
            <span  class="t1">
              ———————
            </span> &nbsp;
      <span style="" class="t2">
              EVERY ULTIMATE PURSUIT
            </span> &nbsp;
      <span class="t1">
              ———————
            </span>
    </div>
    <div class="advantageContainer">
      <div class="advantageLeft">
        <div class="title1">
              <span class="t1">
                  我们四大服务优势保障
                </span>
          <span style=";" class="t2">
                  0风险
                </span>
          <div>
            <div>
            </div>
          </div>
        </div>
        <div class="title2">
          OUR FOUR SERVICE ADVANTAGES TO PROTECT THE RISK OF ZERO
        </div>
        <div class="content">
          <div class="checkerboard">
            <img src="../../assets/shouye/advantage/1.png" class="img"/>
            <div class="title">
              <div class="t1">
                468套风格不同的客房、优雅的就餐环境、丰富的娱乐设施
              </div>
              <div class="t2">
                满足会议、商务、旅行、度假的各类需求，足不出户，应有尽有。
              </div>
            </div>
          </div>
          <div class="checkerboard">
            <img src="../../assets/shouye/advantage/2.png" class="img"/>
            <div class="title">
              <div class="t1">
                提供公司年会场地和婚礼策划等定制服务
              </div>
              <div class="t2">
                22个不同规格的会议室及专业会议配套设施，为您提供全方位的会议服务;配有专业的婚礼策划团队，可满足不同的婚宴需求。
              </div>
            </div>
          </div>
          <div class="checkerboard">
            <img src="../../assets/shouye/advantage/3.png" class="img"/>
            <div class="title">
              <div class="t1">
                党政机关会议定点单位
              </div>
              <div class="t2">
                中央及北京市政府采购定点单位，承接各类政府会议。
              </div>
            </div>
          </div>
          <div class="checkerboard">
            <img src="../../assets/shouye/advantage/4.png" class="img"/>
            <div class="title">
              <div class="t1">
                优美的户外环境，超大的停车空间
              </div>
              <div class="t2">
                占地12万平方米，1200米塑胶跑道，上千平米户外草坪，280余个地上地下停车位。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantageRight"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "advantages"
}
</script>

<style scoped lang="less">
.advantages{
  width: 100%;
  height: 900px;
  //background-color: skyblue;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .title1{
    padding-top: 50px;
    .t1{
      font-size: 36px;
      color:#333333;
      .t2{
        color: #f47110;
        font-size: 75px;
      }
    }
  }
  .title2{
    padding-bottom: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    .t1{
      color: #e5e5e5;
    }
    .t2{
      color: #555555;
    }
  }
  .advantageContainer{
    width: 1200px;
    height: 600px;
    //background-color: sandybrown;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .advantageLeft{
      width: 580px;
      height: 600px;
      //background-color: lavender;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title1{
        height: 10px;
        line-height: 10px ;
        .t1{
          font-size: 30px;
          color: #2b2b2b;
        }
        .t2{
          font-size: 30px;
          color: #ff7205
        }
      }
      .title2{
        font-size: 12px;
        color: #999999;
        height: 30px;
      }
      .content{
        width: 100%;
        height: 450px;
        //background-color: darkcyan;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-left: 60px;
        .checkerboard{
          width: 100%;
          height: 72px;
          //background-color: #e1fcff;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          .img{
            width: 72px;
            height: 72px;
            line-height: 72px;
            background-color: lavenderblush;
          }
          .title{
            width: 400px;
            height: 70px;
            //background-color: gold;
            text-align: left;
            box-sizing: border-box;
            padding-left: 10px;
            line-height: 25px;
            .t1{
              width: 340px;
              color: #333333;
              font-size: 14px;
            }
            .t2{
              color: #999999;
              font-size: 13px;
            }
          }
        }
      }
    }
    .advantageRight{
      width: 550px;
      height: 600px;
      background-color: salmon;
      background:url("../../assets/shouye/advantage/right.png") no-repeat top;
    }
  }

}

</style>