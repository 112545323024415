<template>
  <div class="abcdBox">
    <div class="title1">
      <span class="title1a">您的满意</span>
      <span class="title1b">是我们不懈的追求</span>
    </div>
    <div class="title2">
      <span class="title2a">———————</span> &nbsp;
      <span class="title2b">EVERY ULTIMATE PURSUIT</span> &nbsp;
      <span class="title2a">———————</span><br>
    </div>
    <div class="abcdContainer">
      <div class="centerBox">
        <img src="../../assets/shouye/abcd/centerboximg.png" height="490" width="490"/></div>
      <div class="budaoweng budaoweng1">
        <div style="height: 35px;"></div>
        <div class="title1">A</div>
        <div class="title2">商务会议</div>
        <div class="title3">多种规格,满足团队需求</div>
      </div>
      <div class="budaoweng budaoweng2">
        <div style="height: 35px;"></div>
        <div class="title1">B</div>
        <div class="title2">温馨客房</div>
        <div class="title3">宾至如归,尊贵享受</div>
      </div>
      <div class="budaoweng budaoweng3">
        <div style="height: 35px;"></div>
        <div class="title1">C</div>
        <div class="title2">特色餐饮</div>
        <div class="title3">特色佳肴,味蕾盛宴</div>
      </div>
      <div class="budaoweng budaoweng4">
        <div style="height: 35px;"></div>
        <div class="title1">D</div>
        <div class="title2">休闲娱乐</div>
        <div class="title3">多种规格,满足团队需求</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "abcdBox",
  data:function (){
    return{

    }
  },
  methods:{

  },
  created() {  },
  mounted() {},
}
</script>

<style scoped lang="less">
.abcdBox{
  width: 100%;
  height: 1000px;
  background-color: white;
  border-top: #999999 solid 1px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .title1{
    text-align: center;
    font-size: 30px;
    .title1a{
      color: #000000;
    }
    .title1b{
      color: #ff7205;
    }
  }
  .title2{
    text-align: center;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    .title2a{
      color: #e5e5e5;
    }
    .title2b{
      color: #555555;
    }
  }
  .abcdContainer{
    width: 1200px;
    height: 1000px;
    background-color: lavender;
    background:url("../../assets/shouye/abcd/abcdbackground.jpg") no-repeat top;
    margin-bottom: 100px;
    //padding: 50px 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .centerBox{
      width: 600px;
      height: 600px;
      background-color: slateblue;
      background:url("../../assets/shouye/abcd/centerboxbackground.png") no-repeat top;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .budaoweng{
      width: 200px;
      height: 300px;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      .title1{
        font-size: 60px;
        color:#ff7205;
        height: 55px;
        line-height: 55px;
      }
      .title2{
        font-size: 20px;
        color:#ff7205;
        height: 40px;
        line-height: 40px;
      }
      .title3{
        font-size: 14px;
        color:lightslategrey;
        height: 16px;
        line-height: 16px;
      }
    }
    .budaoweng1{
      background-color: salmon;
      background:url("../../assets/shouye/abcd/budaoweng1.png") no-repeat top;
      left: 120px;
      top: 30px;
    }
    .budaoweng2{
      background-color: skyblue;
      background:url("../../assets/shouye/abcd/budaoweng2.png") no-repeat top;
      right: 120px;
      top: 30px;
    }
    .budaoweng3{
      background-color: #e4e4e4;
      background:url("../../assets/shouye/abcd/budaoweng3.png") no-repeat top;
      left: 120px;
      bottom: 30px;
    }
    .budaoweng4{
      background-color: darkcyan;
      background:url("../../assets/shouye/abcd/budaoweng4.png") no-repeat top;
      right: 120px;
      bottom: 30px;
    }

  }
}
</style>