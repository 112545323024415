<template>
  <div class="footer">
    <div class="content">
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-dianhua icon"></i>
        </div>
        <div class="text">
          联系电话:<br>
          010-60713333
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-icon_xinjian icon"></i>
        </div>
        <div class="text">
          联系邮箱:<br>
          hotel668@163.com
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-ditu icon"></i>
        </div>
        <div class="text">
          酒店地址:<br>
          北京昌平区水库路21号(附近十三陵水库)
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="c0"></div>
      <div class="c1">
        <div class="footerInfo footerInfoV2 J_footerInfo">©2018 中石化会议中心 版权所有</div>
        <div class="footerInfo footerInfoV2 J_footerInfo">技术支持：中企集成|手机版|管理登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="less">

.footer{
  width: 100%;
  height: 230px;
  background-color: #2B2B2B;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .content{
    width: 100%;
    height: 50%;
    //background-color: salmon;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item{
      width: 20%;
      height: 100%;
      //background-color: seagreen;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .icon{
        width: 60px;
        height: 60px;
        line-height: 60px;
        //background-color: slateblue;
        font-size: 60px;
        color: white;
      }
      .text{
        width: 160px;
        height: 60px;
        //background-color: sandybrown;
        font-family: 微软雅黑;
        font-size: 15px;
        color: white;
        text-align: left;
        line-height: 30px;
        padding-left: 10px;
      }
    }
  }
  .copyright{
    width: 100%;
    height: 50%;
    //background-color: skyblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .c0{
      width: 1150px;
      height: 10px;
      border-top: 1px slategray solid;
    }
    .c1{
      width: 1150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      text-align: left;
      font-family: 微软雅黑;
      font-size: 14px;
      color: white;
      line-height: 20px;
    }
  }
}
</style>