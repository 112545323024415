<template>
  <div class="selectBox">
    <div class="selectContainer">
      <div class="keywordBox">
        <span >热门关键词：</span>
        <span style="color: #999999;">大床房、报告厅、婚宴、娱乐、餐饮</span>
      </div>
      <div class="inputBox">
        <el-input v-model="inputValue" placeholder="请输入搜索关键字.."></el-input>
        <el-button class="selectButton" slot="append" icon="el-icon-search" @click="handleClick"></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectBox",
  data:function (){
    return{
      inputValue: ''
    }
  },
  methods:{
    handleClick() {
      console.log(this.inputValue);
    }
  },
  created() {  },
  mounted() {},
}
</script>


<style scoped lang="less">
.selectBox{
  width: 100%;
  height: 90px;
  //background-color: beige;
  border-top: 2px solid #FF7105;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .selectContainer{
    width: 1200px;
    height: 90px;
    //background-color: sandybrown;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-family: 微软雅黑;
    .keywordBox{
      width: 400px;
      height: 90px;
      //background-color: green;
      line-height: 90px;
      text-align: left;
    }
    .inputBox{
      width: 400px;
      height: 90px;
      //background-color: red;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .selectButton{
        background-color: #FF7A1A;
        color: white;
      }
    }

  }
}
</style>