<template>
  <div>
    <Lunbotu></Lunbotu>
    <SelectBox></SelectBox>
    <AbcdBox></AbcdBox>
    <ServicesBox></ServicesBox>
    <Advantages></Advantages>
  </div>
</template>

<script>
import Lunbotu from "@/components/shouye/lunbotu";
import SelectBox from "@/components/shouye/selectBox";
import AbcdBox from "@/components/shouye/abcdBox";
import ServicesBox from "@/components/shouye/servicesBox";
import Advantages from "@/components/shouye/advantages";
export default {
name: "index",
  components: {Lunbotu,SelectBox,AbcdBox,ServicesBox,Advantages},
  data:function (){
  return{
  }
  },
  methods:{

  },
  created() {  },
  mounted() {},
}
</script>

<style scoped lang="less">

</style>