<template>
  <el-carousel :interval="2000" arrow="always" height="650px" :indicator-position="'none'">
    <el-carousel-item v-for="(item, index) in list" :key="index" >
      <img :src="item" alt="">
    </el-carousel-item>
    <template #arrow-left>
      <el-icon class="left-icon" icon="el-icon-arrow-left"  @click="prev"></el-icon>
    </template>
    <template #arrow-right>
      <el-icon class="right-icon" icon="el-icon-arrow-right"  @click="next"></el-icon>
    </template>
  </el-carousel>
</template>

<script>

export default {
  name: "lunbotu",
  data:function (){
    return{
      list: [
        require("../../assets/shouye/lunbotu1.jpeg"),
        require("../../assets/shouye/lunbotu2.jpeg"),
        require("../../assets/shouye/lunbotu3.jpeg"),
        require("../../assets/shouye/lunbotu4.jpeg"),
      ],
    }
  },
  methods:{
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    }
  },
}
</script>

<style scoped lang="less">
::v-deep .el-carousel__arrow {
  //display: none;
  //background-color: red;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  font-size: 60px;
  i{
    /* 使用transform的scale方法来缩小图标 */
    transform: scaleY(0.8);
    transition: transform 0.3s ease;
  }
}
::v-deep .el-carousel__arrow--left{
  left: 80px;
}
::v-deep .el-carousel__arrow--right{
  right: 80px;
}




</style>